import React, { useEffect, useState, useRef } from "react";
import {
  useContract,
  useValidDirectListings,
  useBuyDirectListing,
} from "@thirdweb-dev/react";
import "./../styles/Home.css";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Loader from "react-spinner-material";
import HomeExplainer from "./Explainer";
import Web3 from "web3";
import MaticIcon from "./../assets/MaticIcon.png";

const nftCollectionAddress = "0x911233A8635b6B3C28749f7Fb04D098102cB8a7a";
const marketplaceAddress = "0x45b73E9270d556030605265C0AFCddc63667f12E";

function Home({ nft, walletAddress }) {
  const [searchTerm, setSearchTerm] = useState("");
  const { contract: marketplace, isLoading: loadingMarketplace } = useContract(
    marketplaceAddress,
    "marketplace-v3",
  );
  const {
    data: nfts,
    isLoading: loadingNfts,
    error: nftsError,
  } = useValidDirectListings(marketplace, {
    tokenContract: nftCollectionAddress,
  });

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  useEffect(() => {
    // Log fetched data and errors for debugging
    console.log("Fetched NFTs:", nfts);
    if (nftsError) {
      console.error("Error fetching listings:", nftsError);
    }
  }, [nfts, nftsError]);

  if (loadingMarketplace || loadingNfts) {
    return (
      <div className="Loader">
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  }

  if (nftsError) {
    return <div>Error loading NFTs from the marketplace.</div>;
  }

  if (!nfts || nfts.length === 0) {
    return (
      <h1 className="px-20 py-10 text-3xl">No items in the marketplace</h1>
    );
  }

  // Filter NFTs based on the search term
  const filteredNfts = nfts.filter(
    (nft) =>
      nft.asset?.name &&
      nft.asset.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div>
      <div className="styled-home-explainer">
        <HomeExplainer />
      </div>
      <div className="HomeH-container">
        <div className="HomeH-header">
          <h1 className="HomeH-title">The Marketplace</h1>
          <div className="HomeH-search-bar">
            <input
              type="text"
              placeholder="Search NFTs..."
              onChange={handleSearchChange}
            />
          </div>
        </div>
        <Carousel
          centerMode={false}
          showArrows={true}
          infiniteLoop={true}
          autoPlay={true}
          interval={3000}
          showThumbs={false}
          showStatus={false}
          useKeyboardArrows={true}
          showIndicators={true}
        >
          {filteredNfts.map((nft, i) => (
            <NFTComponent
              key={i}
              nft={nft}
              marketplace={marketplace}
              walletAddress={walletAddress}
              creatorAddress={nft.creatorAddress || "Unknown Creator"}
              quantity={nft.quantity} // Assuming quantity is always provided
            />
          ))}
        </Carousel>
      </div>
    </div>
  );
}

function NFTComponent({
  nft,
  marketplace,
  walletAddress,
  creatorAddress,
  type,
}) {
  const { image, name, description, metadata } = nft.asset || {};

  const pricePerToken = nft.pricePerToken || "0";
  const quantity = nft.quantity || "1";
  const listingId = nft.id || "";

  const priceInMatic = Web3.utils.fromWei(pricePerToken, "ether");
  const { mutateAsync, isLoading, error } = useBuyDirectListing(marketplace);

  const handleBuyClick = async () => {
    try {
      const result = await mutateAsync({
        listingId,
        quantity: "1",
        buyer: walletAddress,
      });

      console.log("Buy result:", result);
    } catch (e) {
      console.error("Buy error:", e);
    }
  };

  const [maticPriceInUSD, setMaticPriceInUSD] = useState(null);
  const livePriceRef = useRef(null);

  useEffect(() => {
    const fetchMaticPrice = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd",
        );
        const data = await response.json();
        const priceInUSD = data["matic-network"].usd;
        setMaticPriceInUSD(priceInUSD);
      } catch (error) {
        console.error("Error fetching MATIC price:", error);
        // Handle the error by preserving the previous price
        if (!maticPriceInUSD) {
          setMaticPriceInUSD("N/A");
        }
      }
    };

    fetchMaticPrice();
    const intervalId = setInterval(fetchMaticPrice, 10000); // Update every 10 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [maticPriceInUSD]);

  const displayCreatorAddress = creatorAddress
    ? `${creatorAddress.substring(0, 4)}...`
    : "Unknown";

  return (
    <div className="HomeH-card">
      <div className="HomeH-card-image-container">
        {image && (
          <img src={image} alt={name || "NFT"} className="HomeH-card-image" />
        )}
        <div className="HomeH-card-badge HomeH-card-creator-badge">
          <span>Creator: {displayCreatorAddress}</span>
        </div>
        <div className="HomeH-card-badge HomeH-card-quantity-badge">
          <span>Quantity: {quantity}</span>
        </div>
      </div>

      <div className="HomeH-card-content">
        <h3 className="HomeH-card-title">{name || "NFT Title"}</h3>
        <p className="HomeH-card-description">
          {description || "NFT Description"}
        </p>
        <p className="HomeH-card-price">
          {priceInMatic} MATIC{" "}
          <img
            src={MaticIcon}
            alt="MATIC"
            style={{ width: "14px", height: "auto", marginLeft: "5px" }}
          />
        </p>
        <p className="HomeH-card-price-usd">
          <span className="price-info">
            1 MATIC ={" "}
            {maticPriceInUSD !== null &&
              maticPriceInUSD.toLocaleString("en-US", {
                style: "currency",
                currency: "USD",
              })}
            <span className="info-text">Live price from coingecko.com</span>
          </span>
        </p>
        <button
          className="HomeH-card-buy-button"
          onClick={handleBuyClick}
          disabled={isLoading}
        >
          {isLoading ? "Buying..." : "Buy"}
        </button>
      </div>
    </div>
  );
}

export default Home;
