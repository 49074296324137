import React, { useState, useEffect } from "react";
import { generateImage } from "./../api/generate/route";
import "./../styles/ImageGenerator.css";
const ImageGenerator = ({
  fileUrl,
  setFileUrl,
  handleGenerateComplete,
  onRetry,
}) => {
  const [imagePrompt, setImagePrompt] = useState("");
  const [generatedImage, setGeneratedImage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [retryCount, setRetryCount] = useState(0);
  const maxRetries = 3; // Maximum number of retries for 504 timeout

  // Function to handle image generation
  const handleGenerate = async () => {
    setIsLoading(true);
    setError(null);

    try {
      const imageData = await generateImage(imagePrompt);
      const base64ImageData = `data:image/png;base64,${imageData[0].b64_json}`;

      setGeneratedImage(base64ImageData);
      setIsLoading(false);

      // Save the generated image just like a user uploaded it
      setFileUrl(base64ImageData);

      // Callback to indicate image generation is complete
      handleGenerateComplete(base64ImageData);
    } catch (error) {
      console.error(error);

      if (error.status === 504 && retryCount < maxRetries) {
        // Retry the request in case of a 504 timeout
        setRetryCount(retryCount + 1);
        onRetry(retryCount + 1);
        setTimeout(handleGenerate, 1000); // Retry after a delay
      } else {
        setIsLoading(false);
        setError("An error occurred while generating the image.");
      }
    }
  };

  // Effect to reset retry count when image prompt changes
  useEffect(() => {
    setRetryCount(0);
  }, [imagePrompt]);

  // Effect to handle navigation confirmation
  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (isLoading) {
        e.preventDefault();
        e.returnValue = "";
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isLoading]);

  const sanitizeFilename = (name) => {
    return name.replace(/[^a-z0-9]/gi, "_").toLowerCase();
  };

  const downloadImage = () => {
    const filename = imagePrompt
      ? `${sanitizeFilename(imagePrompt)}.png`
      : "generated-image.png";
    const link = document.createElement("a");
    link.href = generatedImage;
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleInputChange = (e) => {
    setImagePrompt(e.target.value);

    // Reset the height to shrink if text is deleted
    e.target.style.height = "auto";

    // Adjust the height based on scrollHeight, up to the maximum
    const maxHeight = 300; // Maximum height in pixels
    e.target.style.height = `${Math.min(e.target.scrollHeight, maxHeight)}px`;
  };

  return (
    <div className="image-generator-container">
      <textarea
        className="image-generator-input"
        value={imagePrompt}
        onChange={(e) => setImagePrompt(e.target.value)}
        placeholder="Enter image prompt"
        disabled={isLoading}
        aria-label="Image prompt"
        onChange={handleInputChange}
      />
      <button
        className="image-generator-button"
        disabled={isLoading || !imagePrompt}
        onClick={handleGenerate}
        aria-label="Generate image"
      >
        Generate Image
      </button>
      {isLoading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {generatedImage && (
        <div className="image-download-container">
          <img
            className="generated-image"
            src={generatedImage}
            alt="Generated"
          />
          <button
            className="image-download-button"
            onClick={downloadImage}
            aria-label="Download image"
          >
            &#x21E9; {/* This is a Unicode character for a downward arrow */}
          </button>
        </div>
      )}
    </div>
  );
};

export default ImageGenerator;
