import React, { useState, useEffect } from "react";
import Web3 from "web3";
import { useNavigate } from "react-router-dom";
import "./../styles/CreateItem.css";
import Spinner from "react-spinner-material";
import {
  nftaddress,
  nftmarketaddress,
  nftmarkettaddressTW,
} from "../config.js";
import NFT from "../NFT.json";
import NFTMarket from "../NFTMarket.json";
import { Tooltip as ReactTooltip } from "react-tooltip";
import { NFTStorage, File } from "nft.storage";
import ImageGenerator from "./ImageGenerator";
// Import the MintingLoader component
import MintingLoader from "./MintingLoader";
import {
  useCreateDirectListing,
  useContract,
  Web3Button,
} from "@thirdweb-dev/react";
import polyscanlogo from "./../assets/polyscanlogo.svg";
import copybutton from "./../assets/copybutton.png";
import DallElogo from "./../assets/dallelogo.png";

export default function CreateItem({ walletAddress }) {
  const [fileUrl, setFileUrl] = useState(null);
  const [formInput, updateFormInput] = useState({
    price: "",
    name: "",
    description: "",
  });
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [step, setStep] = useState(1); // Track the current step
  const navigate = useNavigate();
  const [transactionReceipt, setTransactionReceipt] = useState(null);

  const [useImageGenerator, setUseImageGenerator] = useState(true); // New state
  const [nftDetails, setNftDetails] = useState({
    name: "",
    description: "",
    price: "",
    image: "",
  });

  const nftStorageClient = new NFTStorage({
    token: process.env.REACT_APP_NFT_STORAGE_API_TOKEN,
  });

  // Additional state for real-time form validation
  const [nameValid, setNameValid] = useState(true);
  const [descriptionValid, setDescriptionValid] = useState(true);
  const [priceValid, setPriceValid] = useState(true);

  //Listing transaction
  const [isTransactionConfirmed, setIsTransactionConfirmed] = useState(false);

  // State to manage MintingLoader visibility and progress
  const [showMintingLoader, setShowMintingLoader] = useState(false);
  const [mintingProgress, setMintingProgress] = useState(0);
  const [showDetails, setShowDetails] = useState(false);
  const toggleDetails = () => setShowDetails(!showDetails);
  const [confirmAttempted, setConfirmAttempted] = useState(false);

  // Function to toggle between image upload and image generation
  const toggleImageGenerator = () => {
    setUseImageGenerator(!useImageGenerator);
    setFileUrl(null); // Reset the file URL when toggling
  };

  // Function to navigate to the Edit Details step
  const goToEditDetails = () => {
    if (!fileUrl) {
      setErrorMessage("Please upload an image before proceeding.");
      return;
    }
    handleStepChange(2);
  };
  // Function to manage step transitions
  const handleStepChange = (newStep) => {
    // Add validation checks here if needed
    setStep(newStep);
  };
  // validation
  useEffect(() => {
    // Validate Name: check if it's not just empty spaces
    setNameValid(formInput.name.trim().length > 0);

    // Validate Description: check if it's not just empty spaces
    setDescriptionValid(formInput.description.trim().length > 0);

    // Validate Price: must be a positive number (integer or decimal up to 2 places)
    setPriceValid(/^(0(\.\d{1,2})?|1(\.0{1,2})?)$/.test(formInput.price));
  }, [formInput]);

  const handleGenerateComplete = (generatedImageData) => {
    // Function to handle the completion of image generation
    processImage(generatedImageData); // Process the generated image
  };
  //const for list pop-up

  const [showListingPopup, setShowListingPopup] = useState(false);
  const [listingStatusMessage, setListingStatusMessage] = useState("");
  const [isListed, setIsListed] = useState(false);

  const processImage = async (imageData) => {
    try {
      setLoading(true);
      setFileUrl(null);

      if (typeof imageData === "string" && imageData.startsWith("data:image")) {
        // Directly use the base64 string if it's a generated image
        setFileUrl(imageData);
      } else if (imageData instanceof File) {
        // If it's an uploaded file, process as usual
        const metadata = await nftStorageClient.store({
          name: formInput.name,
          description: formInput.description,
          image: imageData,
        });

        // Extracting the CID without the '/metadata.json'
        const cid = metadata.url.replace("ipfs://", "").split("/")[0];

        // Fetching the metadata JSON from the constructed URL
        const metadataUrl = `https://${cid}.ipfs.dweb.link/metadata.json`;
        const response = await fetch(metadataUrl);
        const metadataJson = await response.json();

        // Extracting and transforming the image URL from the metadata
        const imageParts = metadataJson.image.replace("ipfs://", "").split("/");
        const imageUrl = `https://${imageParts[0]}.ipfs.dweb.link/${imageParts
          .slice(1)
          .join("/")}`;

        setFileUrl(imageUrl); // Setting the transformed image URL
        setLoading(false);
        setErrorMessage("");
      } else {
        throw new Error("Invalid image data");
      }

      setLoading(false);
    } catch (error) {
      console.error("Error processing image: ", error);
      setLoading(false);
      setErrorMessage("Error processing the image. Please try again.");
    }
  };

  const onChange = async (e) => {
    if (useImageGenerator) return;

    const file = e.target.files[0];
    if (!file) return;

    processImage(file);
  };

  // MINT ACTION: smart contract interaction using Thirdweb's Web3button
  const mintNFTAction = async (contract) => {
    setShowMintingLoader(true);
    const { name, description } = formInput;
    try {
      const file = new File(
        [JSON.stringify({ name, description, image: fileUrl })],
        "metadata.json",
        { type: "application/json" },
      );

      const cid = await nftStorageClient.storeBlob(file);

      // Adjusted metadata URL to use the NFT.storage format
      const metadataUrl = `https://${cid}.ipfs.nftstorage.link/`;

      // Log the adjusted metadata URL
      console.log("Adjusted Metadata URL:", metadataUrl);
      // Convert the input MATIC price to wei for the smart contract

      const transaction = await contract.call("createToken", [metadataUrl]);
      setTransactionReceipt(transaction.receipt);

      // Print the transaction receipt to the console for inspection
      console.log("Transaction Receipt:", transaction.receipt);

      setSuccessMessage("NFT Minted Successfully!");
      setNftDetails({
        name,
        description,
        price: formInput.price,
        image: fileUrl,
      });
    } catch (error) {
      console.error("Error creating NFT:", error);
      setErrorMessage("Error creating NFT. Please try again.");
    } finally {
      setShowMintingLoader(false);
      handleStepChange(4);
      updateFormInput({ price: "", name: "", description: "" });
      setFileUrl(null);
    }
  };

  // LIST ACTION: Assuming nftmarketaddressTW is your marketplace contract address
  const { contract } = useContract(nftmarkettaddressTW, "marketplace-v3");
  const {
    mutateAsync: createDirectListing,
    isLoading: isListingLoading,
    error: listingError,
  } = useCreateDirectListing(contract);

  // HandleList
  const handleListNFT = async () => {
    setShowListingPopup(true);
    let message = "Starting to list NFT...";
    console.log(message);
    setListingStatusMessage(message);

    if (!transactionReceipt || !nftDetails) {
      message = "No transaction receipt or NFT details available";
      console.error(message);
      setListingStatusMessage(message);
      setShowListingPopup(false);
      return;
    }

    try {
      console.log("Transaction receipt available, extracting tokenId...");
      setListingStatusMessage(
        "Transaction receipt available, extracting tokenId...",
      );
      const tokenIdEvent = transactionReceipt.events[1];
      const tokenId = tokenIdEvent.args.tokenId.toString(); // Convert BigNumber to string

      console.log("Extracted tokenId:", tokenId);
      setListingStatusMessage("Extracted tokenId: " + tokenId);

      console.log("Preparing listing details...");
      setListingStatusMessage("Preparing listing details...");

      const listingDetails = {
        assetContractAddress: nftaddress,
        tokenId: tokenId,
        pricePerToken: nftDetails.price,
        isReservedListing: false,
        quantity: 1,
        startTimestamp: new Date(),
        endTimestamp: new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000),
      };
      console.log("Listing details:", listingDetails);

      // Call createDirectListing and wait for the transaction to be confirmed
      await createDirectListing(listingDetails);
      setIsTransactionConfirmed(true); // Set the state to indicate transaction is confirmed
      setListingStatusMessage("NFT successfully Listed!", listingDetails);
      setIsListed(true);
    } catch (error) {
      setListingStatusMessage("Error during listing: " + error.message);
      setIsTransactionConfirmed(false); // Reset transaction confirmation state on error
      setShowListingPopup(false);
      console.error("Error preparing listing details:", error);
    }
  };

  // Pop-up component
  const ListingPopup = () => {
    return (
      <div className="listing-popup">
        <p>{listingStatusMessage}</p>
      </div>
    );
  };
  // JSX for the component
  return (
    <div className="create-item-container">
      <div className="create-item-flex create-item-justify-center">
        <div className="create-item-wrapper">
          {/* Step Indicator */}
          <div className="step-indicator">
            <div
              className={`step ${step === 1 ? "active" : ""} ${
                fileUrl ? "completed" : ""
              }`}
              onClick={() => handleStepChange(1)}
              data-tip="Upload an image for your NFT here."
            >
              Prompt AI {fileUrl && <i className="fas fa-check-circle"></i>}
            </div>
            <div
              className={`step ${step === 2 ? "active" : ""} ${
                formInput.name && formInput.description && formInput.price
                  ? "completed"
                  : ""
              }`}
              onClick={() => handleStepChange(2)}
              data-tip="Enter details for your NFT."
            >
              Add Details{" "}
              {formInput.name && formInput.description && formInput.price && (
                <i className="fas fa-check-circle"></i>
              )}
            </div>
            <div
              className={`step ${step === 3 ? "active" : ""} ${
                successMessage ? "completed" : ""
              }`}
              data-tip="Confirm and create your NFT."
            >
              Mint ART{" "}
              {successMessage && <i className="fas fa-check-circle"></i>}
            </div>
          </div>
          {/* File Upload Step */}
          {step === 1 && (
            <div className="create-item-step">
              <div className="toggle-switch-container">
                <label className="toggle-switch">
                  <input
                    type="checkbox"
                    checked={useImageGenerator}
                    onChange={toggleImageGenerator}
                  />
                  <span className="slider"></span>
                </label>
                <span className="toggle-switch-label">
                  {useImageGenerator ? (
                    <>
                      AI Image Generator by
                      <img
                        src={DallElogo}
                        alt="Dall-E Logo"
                        className="dall-e-logo"
                      />
                    </>
                  ) : (
                    "Upload Image"
                  )}
                </span>
              </div>

              {!useImageGenerator && (
                <div>
                  <input
                    type="file"
                    name="Asset"
                    className="create-item-file-input"
                    onChange={onChange}
                  />
                  {loading && (
                    <div className="create-item-loading">
                      <Spinner
                        size={60}
                        spinnerColor={"#333"}
                        spinnerWidth={2}
                        visible={true}
                      />
                    </div>
                  )}
                </div>
              )}

              {useImageGenerator && (
                <ImageGenerator
                  fileUrl={fileUrl}
                  setFileUrl={setFileUrl}
                  handleGenerateComplete={handleGenerateComplete}
                />
              )}

              {fileUrl && (
                <>
                  <button
                    onClick={goToEditDetails}
                    className="create-item-button"
                  >
                    Next: Edit Details
                  </button>
                  <button
                    onClick={() => setFileUrl(null)}
                    className="create-item-delete-button"
                  >
                    Delete NFT
                  </button>
                </>
              )}
            </div>
          )}

          {/* Edit Details Step */}
          {step === 2 && (
            <div className="create-item-step">
              {/* Name Input */}
              <input
                placeholder="Asset Name"
                className={`create-item-input ${
                  !nameValid && confirmAttempted ? "input-error-nudge" : ""
                } ${
                  !nameValid && confirmAttempted ? "input-error-border" : ""
                }`}
                onChange={(e) => {
                  updateFormInput({ ...formInput, name: e.target.value });
                  setNameValid(e.target.value.trim().length > 0);
                }}
                value={formInput.name}
              />
              {!nameValid && confirmAttempted && (
                <p className="input-error-message">Name cannot be empty.</p>
              )}

              {/* Description Input */}
              <textarea
                placeholder="Asset Description"
                className={`create-item-textarea ${
                  !descriptionValid && confirmAttempted
                    ? "input-error-nudge"
                    : ""
                } ${
                  !descriptionValid && confirmAttempted
                    ? "input-error-border"
                    : ""
                }`}
                onChange={(e) => {
                  updateFormInput({
                    ...formInput,
                    description: e.target.value,
                  });
                  setDescriptionValid(e.target.value.trim().length > 0);
                }}
                value={formInput.description}
              />
              {!descriptionValid && confirmAttempted && (
                <p className="input-error-message">
                  Description cannot be empty.
                </p>
              )}

              {/* Price Input */}
              <input
                placeholder="Asset Price in MATIC"
                className={`create-item-input ${
                  !priceValid && confirmAttempted ? "input-error-nudge" : ""
                } ${
                  !priceValid && confirmAttempted ? "input-error-border" : ""
                }`}
                onChange={(e) => {
                  updateFormInput({ ...formInput, price: e.target.value });
                  setPriceValid(/^\d+(\.\d{1,2})?$/.test(e.target.value));
                }}
                value={formInput.price}
              />
              {!priceValid && confirmAttempted && (
                <p className="input-error-message">
                  Invalid price format. Please enter a price less than 1 matic.
                </p>
              )}

              {/* confirm Button */}
              <button
                onClick={() => {
                  setConfirmAttempted(false);
                  setTimeout(() => {
                    setConfirmAttempted(true);
                  }, 0);

                  if (!fileUrl) {
                    // If no file is uploaded, go back to Step 1 and show an error message
                    setErrorMessage("Please upload an image.");
                    handleStepChange(1);
                    return;
                  }

                  if (!nameValid || !descriptionValid || !priceValid) {
                    // Do not proceed to the next step if any validation fails
                    return;
                  }

                  handleStepChange(3); // Proceed to the next step only if all validations pass
                }}
                className="create-item-button"
              >
                Confirm
              </button>

              {/* Previous Button */}
              <button
                onClick={() => handleStepChange(1)}
                className="create-item-button"
              >
                Previous: Prompt AI
              </button>
            </div>
          )}

          {/* Confirmation Step */}
          {/* Confirmation Step */}
          {step === 3 && (
            <div className="create-item-step">
              <h2>Confirmation</h2>
              {/* Display the uploaded image and details here */}
              {fileUrl && (
                <>
                  <img
                    className="create-item-image"
                    src={fileUrl}
                    alt="NFT preview"
                  />
                  <div className="nft-details">
                    <p className="nft-detail name">Name: {formInput.name}</p>
                    <p className="nft-detail description">
                      Description: {formInput.description}
                    </p>
                    <p className="nft-detail price">
                      Price in MATIC: {formInput.price}
                    </p>
                  </div>
                  <Web3Button
                    contractAddress={nftaddress}
                    contractAbi={NFT.abi}
                    action={(contract) => mintNFTAction(contract)}
                    className="create-item-button-Mint"
                  >
                    Mint NFT
                  </Web3Button>
                </>
              )}
            </div>
          )}

          {/* Error and Success Messages */}
          {errorMessage && (
            <div className="create-item-error">
              <p>{errorMessage}</p>
            </div>
          )}
          {successMessage && (
            <div className="create-item-success">
              {/* Success Message */}
              <div className="success-message-container">
                <p className="success-message">{successMessage}</p>
                {transactionReceipt && !isListed && (
                  <Web3Button
                    contractAddress={nftmarkettaddressTW}
                    action={async () => {
                      const listingParams = await handleListNFT();
                      if (listingParams) {
                        console.log(
                          "Executing createDirectListing with prepared details...",
                        );
                        try {
                          await createDirectListing(listingParams);
                          console.log(
                            "NFT listed successfully on the marketplace.",
                          );
                        } catch (error) {
                          console.error("Error during listing:", error);
                        }
                      } else {
                        console.log("No listing parameters available");
                      }
                    }}
                    disabled={isListingLoading}
                  >
                    List NFT
                  </Web3Button>
                )}
              </div>

              {/* NFT Image and Details */}
              {transactionReceipt && (
                <div className="nft-content">
                  <div className="nft-image-container">
                    <img
                      className="nft-preview-image"
                      src={nftDetails.image}
                      alt="NFT preview"
                    />
                    {isListed && (
                      <div className="nft-listed-badge">
                        <span className="badge-icon">&#10004;</span>
                        <span className="badge-text">Listed!</span>
                      </div>
                    )}
                  </div>

                  <div className="nft-details">
                    <p className="nft-detail name">{nftDetails.name}</p>
                    <p className="nft-detail description">
                      {nftDetails.description}
                    </p>
                    <p className="nft-detail price">{nftDetails.price} MATIC</p>
                  </div>

                  {/* Show Transaction Details Button */}
                  <button
                    onClick={toggleDetails}
                    className="Blockchain-details-button"
                  >
                    {showDetails
                      ? "Hide Blockchain Data"
                      : "Show Blockchain Data"}
                  </button>

                  {/* Transaction Modal */}
                  {showDetails && (
                    <div className="transaction-modal">
                      <div className="modal-content">
                        <span className="close-button" onClick={toggleDetails}>
                          &times;
                        </span>
                        <div className="blockchain-data">
                          <p>
                            Transaction Hash:
                            <span> {transactionReceipt.transactionHash} </span>
                            <button
                              onClick={() =>
                                navigator.clipboard.writeText(
                                  transactionReceipt.transactionHash,
                                )
                              }
                              className="copy-button"
                              title="Copy to Clipboard"
                            >
                              <img src={copybutton} alt="Copy" />
                            </button>
                          </p>
                          <p>Block Hash: {transactionReceipt.blockHash}</p>
                          <p>Block Number: {transactionReceipt.blockNumber}</p>
                          <p>
                            Gas Used: {transactionReceipt.gasUsed.toString()}
                          </p>
                          {/* PolyScan Button */}
                          <button
                            onClick={() =>
                              window.open(
                                `https://mumbai.polygonscan.com/tx/${transactionReceipt.transactionHash}`,
                                "_blank",
                              )
                            }
                            className="polyscan-button"
                          >
                            <img src={polyscanlogo} alt="View on PolyScan" />
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}

          {/* Listing Popup */}
          {showListingPopup && !isTransactionConfirmed && <ListingPopup />}

          {/* Help Section */}
          <div className="create-item-help">
            {/* Tooltip Trigger Element */}
            <span className="create-item-tooltip">
              i{" "}
              <span className="create-item-tooltip-text">
                {step === 1 && "Upload an image for your NFT here."}
                {step === 2 &&
                  "Add details about your NFT, the name, description, and price."}
                {step === 3 &&
                  "Review and confirm your NFT details before minting."}
              </span>
            </span>

            {/* ReactTooltip Component */}
            <ReactTooltip place="top" effect="solid" />
          </div>

          {/* Display MintingLoader when showMintingLoader is true */}
          {showMintingLoader && (
            <MintingLoader
              onClose={() => {
                setShowMintingLoader(false);
                // You can perform additional actions here when the loader is closed.
              }}
              progress={mintingProgress}
              // Add any additional props you want to pass to MintingLoader
            />
          )}
        </div>
      </div>
    </div>
  );
}
