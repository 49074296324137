/* global smartlook */

import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  NavLink,
} from "react-router-dom";
import {
  ThirdwebProvider,
  ConnectWallet,
  useAddress,
  useMetamask,
  metamaskWallet,
  coinbaseWallet,
  walletConnect,
  localWallet,
  embeddedWallet,
  smartWallet,
  lightTheme,
  en,
} from "@thirdweb-dev/react";
import Home from "./pages/Home";
import CreateItem from "./pages/CreateItem";
import CreatorDashboard from "./pages/CreatorDashboard";
import "./styles/globals.css";

import soElLogo from "./assets/SOEL-LOGO2.png";
import linkedinIcon from "./assets/linkedin.png";
import instagramIcon from "./assets/instagram.png";
// Inside your main component file or TermsModal.js if it's a separate file
import TermsAndConditions from "./pages/TermsAndConditions"; // Import the new component

//vercel analytics
import { Analytics } from "@vercel/analytics/react";

const smartWalletOptions = {
  factoryAddress: "0x44be02b68fb3e53c395cd51038105658523c039c",
  gasless: true,
};

const supportedWallets = [
  smartWallet(metamaskWallet(), smartWalletOptions),
  smartWallet(coinbaseWallet({ recommended: true }), smartWalletOptions),
  smartWallet(walletConnect(), smartWalletOptions),
  smartWallet(localWallet(), smartWalletOptions),
  smartWallet(
    embeddedWallet({
      auth: {
        options: ["email", "google", "apple", "facebook"],
      },
    }),
    smartWalletOptions,
  ),
];

function App() {
  useEffect(() => {
    // Create a new script element
    const script = document.createElement("script");

    // Set the script type
    script.type = "text/javascript";

    // Set the script content (your provided script)
    script.innerHTML = `
      window.smartlook||(function(d) {
        var o=smartlook=function(){ o.api.push(arguments)},h=d.getElementsByTagName('head')[0];
        var c=d.createElement('script');o.api=new Array();c.async=true;c.type='text/javascript';
        c.charset='utf-8';c.src='https://web-sdk.smartlook.com/recorder.js';h.appendChild(c);
      })(document);
      smartlook('init', 'a95811acd1ef2bec9119e6b59a3855bef9c90974', { region: 'eu' });
    `;

    // Append the script element to the head
    document.head.appendChild(script);

    // Clean up by removing the script element when the component unmounts
    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const walletAddress = useAddress();

  const [currentBackground, setCurrentBackground] = useState(1);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentBackground((prev) => (prev === 3 ? 1 : prev + 1));
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleCheckboxChange = (event) => {
    setTermsAccepted(event.target.checked);
  };
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const handleAcceptTerms = () => {
    setTermsAccepted(true);
  };
  const toggleTermsModal = () => {
    setIsTermsModalOpen(!isTermsModalOpen);
  };
  // State to manage navbar toggle
  const [isNavbarExpanded, setIsNavbarExpanded] = useState(false);

  // Function to toggle navbar expansion
  const toggleNavbar = () => {
    setIsNavbarExpanded(!isNavbarExpanded);
  };

  //terms and condidtions modal

  const TermsModal = ({ isOpen, onClose, onAccept }) => {
    if (!isOpen) return null;

    return (
      <div className="modal-overlay">
        <div className="modal-content">
          <button onClick={onClose} className="close-button">
            X {/* Close button text */}
          </button>
          <TermsAndConditions />
          <div className="modal-actions">
            <button
              onClick={() => {
                onAccept();
                onClose();
              }}
              className="accept-button"
            >
              Accept
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (!walletAddress) {
    return (
      <div className={`AppM-welcome-page bg-image-${currentBackground}`}>
        <h1 className="AppM-welcome-title">So-EL ART</h1>
        <p className="AppM-welcome-message">BLOCKCHAIN X AI</p>
        <p className="AppM-welcome-message2">
          Use DALL-E 3 TO CREATE AND TRADE BEAUTIFUL NFTS
        </p>
        {/* Terms and Conditions Checkbox */}
        <div className="terms-checkbox">
          <input
            type="checkbox"
            id="termsConditions"
            checked={termsAccepted}
            onChange={handleCheckboxChange}
          />
          <label htmlFor="termsConditions">
            I accept the{" "}
            <span className="terms-highlight" onClick={toggleTermsModal}>
              Terms and Conditions
            </span>
          </label>
        </div>
        <div className={`connect-button ${!termsAccepted ? "disabled" : ""}`}>
          <ConnectWallet theme={"dark"} modalSize={"compact"} />
        </div>
        {/* TermsModal component */}
        <TermsModal
          isOpen={isTermsModalOpen}
          onClose={toggleTermsModal}
          onAccept={handleAcceptTerms}
        />
      </div>
    );
  }

  return (
    <Router>
      <div>
        <nav
          className={`AppM-navbar border-b p-6 ${
            isNavbarExpanded ? "expanded" : ""
          }`}
        >
          <div className="AppM-navbar-content">
            <h1 className="AppM-welcome-title">So-EL ART</h1>
            <button className="navbar-toggle" onClick={toggleNavbar}>
              {
                isNavbarExpanded ? (
                  <span className="navbar-toggle-icon">&#x25BC;</span> // Arrow down
                ) : (
                  <span className="navbar-toggle-icon">&#x25B2;</span>
                ) // Arrow up
              }
            </button>
          </div>
          <div className="AppM-links">
            <NavLink
              end
              to="/Home"
              className={({ isActive }) =>
                isActive ? "AppM-link AppM-link-active" : "AppM-link"
              }
            >
              Home
            </NavLink>
            <NavLink
              to="/"
              className={({ isActive }) =>
                isActive ? "AppM-link AppM-link-active" : "AppM-link"
              }
            >
              Create ART
            </NavLink>
            <NavLink
              to="/dashboard"
              className={({ isActive }) =>
                isActive ? "AppM-link AppM-link-active" : "AppM-link"
              }
            >
              Dashboard
            </NavLink>
          </div>
          <ConnectWallet
            theme={"dark"} //
            modalSize={"compact"}
            welcomeScreen={{}} //
            modalTitleIconUrl={soElLogo} //
          />
        </nav>

        <Routes>
          <Route
            exact
            path="/"
            element={<CreateItem walletAddress={walletAddress} />}
          />
          <Route
            path="/Home"
            element={<Home walletAddress={walletAddress} />}
          />

          <Route
            path="/dashboard"
            element={<CreatorDashboard walletAddress={walletAddress} />}
          />
        </Routes>

        <footer className="AppM-footer">
          <p className="AppM-footer-text">
            Empowering Innovation: So-EL ART, a fusion of blockchain and AI, is
            proudly presented by So-El, Inc. - a dedicated startup studio.
            Please note that this application is a test platform for educational
            purposes only. All content, including the technology, design, and
            intellectual property related to this app, is owned by So-El, Inc.
            and is protected under applicable intellectual property laws. All
            rights are reserved. Unauthorized copying, modification,
            distribution, or use of this application, or any part thereof, is
            strictly prohibited and may be subject to legal action. For more
            information or inquiries, please visit{" "}
            <a
              href="https://www.so-el.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              www.so-el.xyz
            </a>{" "}
            to learn more.
          </p>
          <div className="AppM-footer-icons">
            <a
              href="https://www.so-el.xyz"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={soElLogo}
                alt="So-El Website"
                className="AppM-footer-icon"
                style={{ width: "40px", height: "40px" }}
              />
            </a>
            <a
              href="https://www.linkedin.com/company/so-el/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={linkedinIcon}
                alt="LinkedIn"
                className="AppM-footer-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/so.el.xyz/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={instagramIcon}
                alt="Instagram"
                className="AppM-footer-icon"
              />
            </a>
          </div>
        </footer>
      </div>
    </Router>
  );
}

function MainApp() {
  return (
    <ThirdwebProvider
      activeChain="mumbai"
      clientId="b044d1c9c8c0846807d39953e18c99a1"
      supportedWallets={supportedWallets}
    >
      <App />
    </ThirdwebProvider>
  );
}

export default MainApp;
