import React, { useState, useEffect } from "react";
import axios from "axios";
import "./../styles/CreatorDashboard.css";
import Loader from "react-spinner-material";
import Modal from "react-modal";
import { nftmarkettaddressTW } from "../config.js";
import {
  useValidDirectListings,
  useContract,
  useCancelDirectListing,
  Web3Button,
} from "@thirdweb-dev/react";
import MintingLoader from "./MintingLoader";

import MaticIcon from "./../assets/MaticIcon.png";

Modal.setAppElement("#root");

export default function CreatorDashboard({ walletAddress }) {
  const [nfts, setNfts] = useState([]);
  const [sold, setSold] = useState([]);
  const [loadingState, setLoadingState] = useState("not-loaded");
  const [selectedNFT, setSelectedNFT] = useState(null);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [activeTab, setActiveTab] = useState("created");
  const { contract } = useContract(nftmarkettaddressTW, "marketplace-v3");
  const {
    data: directListings,
    isLoading,
    error: directListingsError,
  } = useValidDirectListings(contract, { seller: walletAddress });
  const {
    mutateAsync: cancelDirectListing,
    isLoading: isCancelLoading,
    error: cancelError,
  } = useCancelDirectListing(contract);

  useEffect(() => {
    loadNFTs();
  }, [directListings, isLoading, directListingsError]);

  //pop-up to cancel listing

  const [showMintingLoader, setShowMintingLoader] = useState(false);
  const [maticPriceInUSD, setMaticPriceInUSD] = useState(null);
  useEffect(() => {
    const fetchMaticPrice = async () => {
      try {
        const response = await fetch(
          "https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd",
        );
        const data = await response.json();
        const priceInUSD = data["matic-network"].usd;
        setMaticPriceInUSD(priceInUSD);
      } catch (error) {
        console.error("Error fetching MATIC price:", error);
        // Handle the error by preserving the previous price
        if (!maticPriceInUSD) {
          setMaticPriceInUSD("N/A");
        }
      }
    };

    fetchMaticPrice();
    const intervalId = setInterval(fetchMaticPrice, 10000); // Update every 10 seconds

    return () => clearInterval(intervalId); // Clean up interval on component unmount
  }, [maticPriceInUSD]);

  // Load NFTs owned by walletAddress
  async function loadNFTs() {
    if (isLoading) {
      setLoadingState("loading");
      return;
    }

    if (directListingsError) {
      console.error("Error fetching direct listings:", directListingsError);
      setError(directListingsError.message);
      setLoadingState("error");
      return;
    }

    if (directListings && directListings.length > 0) {
      console.log("Direct listings data:", directListings); // Add this line to log the direct listings data
      const items = await Promise.allSettled(
        directListings.map(async (listing) => {
          try {
            // Convert IPFS URL for Axios compatibility
            const metadataUrl = listing.asset.uri.replace(
              "ipfs://",
              "https://ipfs.io/ipfs/",
            );
            const metaResponse = await axios.get(metadataUrl);
            const metadata = metaResponse.data;

            return {
              price: Number(listing.pricePerToken) / 1e18, // Convert Wei to Matic
              tokenId: listing.id,
              seller: listing.seller,
              owner: listing.owner,
              image: metadata.image,
              name: metadata.name,
              description: metadata.description,
              sold: listing.sold, // Adjust as needed
            };
          } catch (error) {
            console.error(`Error processing listing:`, error);
            return null;
          }
        }),
      );

      const validItems = items
        .filter(
          (result) => result.status === "fulfilled" && result.value != null,
        )
        .map((result) => result.value);

      setNfts(validItems);
      setLoadingState("loaded");
    } else {
      setNfts([]);
      setLoadingState("loaded");
    }
  }

  // De-List NFTs owned by walletAddress
  const delistNFTAction = async (listingId) => {
    setShowMintingLoader(true); // Show loader when delisting starts
    console.log(listingId);
    try {
      // Call thirdweb's cancelDirectListing to delist the NFT
      await cancelDirectListing(listingId);
      console.log(`Listing ${listingId} cancelled`);
      // Additional success handling can go here
    } catch (error) {
      console.error("Error canceling listing:", error);
      // Error handling
    } finally {
      setShowMintingLoader(false); // Hide loader when delisting is complete
      loadNFTs(); // Refresh the NFT data
    }
  };
  // Function to open the modal with the selected NFT
  const openModal = (nft) => {
    setSelectedNFT(nft);
  };

  // Function to close the modal
  const closeModal = () => {
    setSelectedNFT(null);
  };

  // Function for displaying error and retry button
  function renderErrorState(errorMessage) {
    return (
      <div className="ErrorState">
        <p>{errorMessage}</p>
        <button onClick={loadNFTs}>Retry</button>
      </div>
    );
  }

  // Function to render empty state message
  function renderEmptyStateMessage(sectionTitle) {
    return (
      <div className="EmptyState">
        <p>No {sectionTitle} to display.</p>
      </div>
    );
  }

  // Render the loader while loading
  if (loadingState === "loading") {
    return (
      <div className="Loader">
        <Loader type="Puff" color="#00BFFF" height={100} width={100} />
      </div>
    );
  }

  // Render error message if data loading failed
  if (loadingState === "error") {
    return renderErrorState("Error loading NFTs. Please try again.");
  }

  // Filter NFTs based on the search term
  const filteredNFTs = nfts.filter(
    (nft) =>
      nft.name && nft.name.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <div className="CreatorC-container">
      {/* Search bar */}
      <input
        type="text"
        placeholder="Search NFTs"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      {/* Tabs for switching views */}
      <div className="CreatorC-tabs">
        <button
          className={`tab-btn ${activeTab === "created" ? "active" : ""}`}
          onClick={() => setActiveTab("created")}
        >
          Items Created
        </button>
        <button
          className={`tab-btn ${activeTab === "sold" ? "active" : ""} disabled`}
          onClick={() => setActiveTab("sold")}
          disabled
          data-tooltip="Coming Soon" // Add a data attribute for the tooltip content
        >
          Items Sold
        </button>
      </div>

      {/* Items Created View */}
      {activeTab === "created" && (
        <>
          <h2 className="CreatorC-section-title">Items Created</h2>
          {!filteredNFTs.length ? (
            renderEmptyStateMessage("Items Created")
          ) : (
            <div className="CreatorC-items-container">
              {filteredNFTs.map((nft, i) => (
                <div key={i} className="CreatorC-item">
                  <img
                    src={nft.image}
                    alt={nft.name}
                    className="CreatorC-image"
                    onClick={() => openModal(nft)}
                  />
                  <div className="TokenID-badge">Token ID: {nft.tokenId}</div>
                  <div className="CreatorC-item-details">
                    <p className="CreatorC-item-name">{nft.name}</p>
                    <p className="CreatorC-item-description">
                      {nft.description}
                    </p>
                    <p className="HomeH-card-price">
                      {nft.price} MATIC{" "}
                      <img
                        src={MaticIcon}
                        alt="MATIC"
                        style={{
                          width: "14px",
                          height: "auto",
                          marginLeft: "5px",
                        }}
                      />
                    </p>
                    <p className="HomeH-card-price-usd">
                      <span className="price-info">
                        1 MATIC ={" "}
                        {maticPriceInUSD !== null &&
                          maticPriceInUSD.toLocaleString("en-US", {
                            style: "currency",
                            currency: "USD",
                          })}
                        <span className="info-text">
                          Live price from coingecko.com
                        </span>
                      </span>
                    </p>
                    <Web3Button
                      className="web3Button"
                      contractAddress={nftmarkettaddressTW}
                      action={() => delistNFTAction(nft.tokenId)}
                      disabled={isCancelLoading}
                    >
                      Cancel Direct Listing
                    </Web3Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {/* Items Sold View */}
      {activeTab === "sold" && (
        <>
          <h2 className="CreatorC-section-title">Items Sold</h2>
          {!sold.length ? (
            renderEmptyStateMessage("Items Sold")
          ) : (
            <div className="CreatorC-items-container">
              {sold.map((nft, i) => (
                <div
                  key={i}
                  className="CreatorC-item"
                  onClick={() => openModal(nft)}
                >
                  <img
                    src={nft.image}
                    alt={nft.name}
                    className="CreatorC-image"
                  />
                  <div className="CreatorC-item-details">
                    <p className="CreatorC-item-name">{nft.name}</p>
                    <p className="CreatorC-item-description">
                      {nft.description}
                    </p>
                    <p className="CreatorC-item-price">{nft.price} MATIC</p>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      )}

      {/* Modal */}
      <Modal
        isOpen={!!selectedNFT}
        onRequestClose={closeModal}
        contentLabel="NFT Details"
        className="Modal"
        overlayClassName="Overlay"
      >
        {selectedNFT && (
          <div className="Modal-content">
            <img
              src={selectedNFT.image}
              alt={selectedNFT.name}
              className="Modal-image"
            />
            <div className="Modal-text">
              <h2>{selectedNFT.name}</h2>
              <p>{selectedNFT.description}</p>
              <p className="Modal-price">Price: {selectedNFT.price} MATIC</p>
              <button onClick={closeModal} className="Modal-close-btn">
                Close
              </button>
            </div>
          </div>
        )}
      </Modal>
      {showMintingLoader && (
        <MintingLoader
          onClose={() => setShowMintingLoader(false)}
          // Additional props for MintingLoader, if needed
        />
      )}
    </div>
  );
}
