import React, { useEffect } from "react";
import { Grid } from "react-loader-spinner";
import gsap from "gsap";
import "./../styles/MintingLoader.css";

const MintingLoader = ({ onClose, isTransactionSuccessful }) => {
  useEffect(() => {
    if (isTransactionSuccessful) {
      // Create GSAP animation to fade out the loader
      gsap.to(".minting-loader-content", {
        opacity: 0,
        duration: 0.5,
        onComplete: () => {
          onClose(); // Close the loader after animation
        },
      });
    }
  }, [isTransactionSuccessful, onClose]);

  return (
    <div className="minting-loader">
      <div className="minting-loader-content">
        <Grid
          type="Grid"
          color="#007bff"
          height={80}
          width={80}
          visible={!isTransactionSuccessful}
          className="loader-spinner" // Added class for the spinner
        />
        <p className="loader-message">
          Minting your NFT, please sign the transactions on your MetaMask and
          wait for the Confirmation message
        </p>
      </div>
    </div>
  );
};

export default MintingLoader;
