import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom"; // Import NavLink for navigation
import "./../styles/Explainer.css";
import createImg from "./../assets/create.png";
import mintImg from "./../assets/mint.png";
import listImg from "./../assets/list.png";

function HomeExplainer() {
  const [activeScreen, setActiveScreen] = useState(0);
  const [animateButton, setAnimateButton] = useState(false);

  useEffect(() => {
    setAnimateButton(true);
    const interval = setInterval(() => {
      setActiveScreen((prevScreen) => (prevScreen + 1) % 3);
      setAnimateButton(false);
      setTimeout(() => setAnimateButton(true), 100); // Slight delay to reset animation
    }, 4350);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="explainer-container">
      {/* Screen 1 */}
      <div
        className={`explainer-page ${activeScreen === 0 ? "active" : ""}`}
        style={{ transform: `translateX(${activeScreen * -100}%)` }}
      >
        <img src={createImg} alt="Create Art" className="explainer-image" />
        <div className="explainer-text">
          <div className="screen-number">Step 1</div> {/* Screen number */}
          <span className="explainer-text-content">
            Use OPEN AI's DALL-E 3 to generate beautiful unique images with a
            simple prompt
          </span>
          <NavLink to="/sell-nfts">
            {" "}
            {/* Use NavLink for navigation */}
            <button
              className={`start-creating-btn ${
                animateButton && activeScreen === 0 ? "animate" : ""
              }`}
            >
              Start Creating
            </button>
          </NavLink>
        </div>
      </div>

      {/* Screen 2 */}
      <div
        className={`explainer-page ${activeScreen === 1 ? "active" : ""}`}
        style={{ transform: `translateX(${activeScreen * -100}%)` }}
      >
        <img src={mintImg} alt="Mint NFT" className="explainer-image" />
        <div className="explainer-text">
          <div className="screen-number">Step 2</div> {/* Screen number */}
          <span className="explainer-text-content">
            Tokenize the generated image into an ERC-721 Non-fungible token
          </span>
          <NavLink to="/sell-nfts">
            {" "}
            {/* Use NavLink for navigation */}
            <button
              className={`start-creating-btn ${
                animateButton && activeScreen === 1 ? "animate" : ""
              }`}
            >
              Start Creating
            </button>
          </NavLink>
        </div>
      </div>

      {/* Screen 3 */}
      <div
        className={`explainer-page ${activeScreen === 2 ? "active" : ""}`}
        style={{ transform: `translateX(${activeScreen * -100}%)` }}
      >
        <img
          src={listImg}
          alt="List on Marketplace"
          className="explainer-image"
        />
        <div className="explainer-text">
          <div className="screen-number">Step 3</div> {/* Screen number */}
          <span className="explainer-text-content">
            List the newly minted NFT on The marketplace for a price of your
            choosing
          </span>
          <NavLink to="/sell-nfts">
            {" "}
            {/* Use NavLink for navigation */}
            <button
              className={`start-creating-btn ${
                animateButton && activeScreen === 2 ? "animate" : ""
              }`}
            >
              Start Creating
            </button>
          </NavLink>
        </div>
      </div>
    </div>
  );
}

export default HomeExplainer;
