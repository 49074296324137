const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions">
      <h2 className="section-title">Terms and Conditions</h2>

      <h3 className="sub-section-title">1. Introduction</h3>
      <p>
        Welcome to the So-EL Inc. NFT Marketplace. This platform facilitates the
        seamless signing in of users (with automatic wallet creation if needed),
        the generation of art using OpenAI's Dall-E 3, minting of these artworks
        as NFTs on the Mumbai chain, and their listing on our marketplace.
      </p>

      <h3 className="sub-section-title">2. User Eligibility</h3>
      <p>
        The use of the So-EL Inc. NFT Marketplace is strictly limited to
        individuals who are 18 years of age or older. By agreeing to these Terms
        and Conditions, you represent and warrant that you are at least 18 years
        old and have the legal capacity to enter into this agreement.
      </p>

      <h3 className="sub-section-title">3. Nature of the Service</h3>
      <p>
        This platform is provided for testing purposes only and is to be used
        non-commercially as a proof of concept. Users should be aware that this
        platform is in a developmental phase and is not a commercial
        marketplace.
      </p>

      <h3 className="sub-section-title">4. Zero Liability</h3>
      <p>
        <strong>No Liability for Loss:</strong> So-EL Inc. shall not be liable
        for any loss of art or NFTs minted on this platform. Users acknowledge
        the risk of loss due to the experimental nature of the technology.
      </p>
      <p>
        <strong>Non-Commercial Use and Compliance:</strong> Users must not
        monetize or use the generated and minted art for commercial purposes.
        All use must comply with OpenAI’s policies and blockchain regulations.
        Any violation of these policies will be the sole responsibility of the
        user.
      </p>
      <p>
        <strong>Securities Law Compliance:</strong> Users in the United States
        and other jurisdictions must ensure that their use of the NFTs does not
        constitute the minting and trading of securities as defined by
        applicable law. So-EL Inc. disclaims all liability in this regard.
      </p>

      <h3 className="sub-section-title">5. Intellectual Property</h3>
      <p>
        Users must respect all intellectual property rights associated with
        content generated by OpenAI's Dall-E 3 and any other content on the
        platform. Compliance with OpenAI's policy is the responsibility of the
        user.
      </p>

      <h3 className="sub-section-title">6. Limitation of Liability</h3>
      <p>
        So-EL Inc. expressly disclaims all liability related to the use of this
        platform. Users agree to hold So-EL Inc. harmless from any claims,
        losses, damages, or liabilities arising out of or in connection with the
        use of this platform.
      </p>

      <h3 className="sub-section-title">7. Acceptance of Terms</h3>
      <p>
        By using the So-EL Inc. NFT Marketplace, you signify your acceptance of
        these Terms and Conditions. If you do not agree to these terms, please
        refrain from using this platform.
      </p>

      <h3 className="sub-section-title">8. Amendments to Terms</h3>
      <p>
        So-EL Inc. reserves the right to modify these Terms and Conditions at
        any time. Your continued use of the platform following any changes
        signifies your acceptance of the new terms.
      </p>

      <h3 className="sub-section-title">9. Governing Law</h3>
      <p>
        These Terms and Conditions shall be governed by and construed in
        accordance with the laws of Delaware, without regard to its conflict of
        law principles.
      </p>

      <h3 className="sub-section-title">10. Contact Information</h3>
      <p>
        For any questions or concerns about these Terms and Conditions, please
        contact us at <a href="mailto:nassif@so-el.xyz">nassif@so-el.xyz</a>.
      </p>
    </div>
  );
};

export default TermsAndConditions;
